import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { Link, PageProps } from "gatsby";
import styled from "styled-components";
import DocumentationSection from "components/docs/DocumentationSection";
import { Globe, User, Users } from "lucide-react";
import LINKS from "components/docs/navigation/links";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";

const TileRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
  margin-top: 20px;
  margin-right: 0px !important;
  padding-right: 0px !important;
  justify-content: space-evenly;
`;

const FieldMappingLinkTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px 20px;
  gap: 7px;
  margin-bottom: 36px;
  margin-right: 0px !important;

  width: 100%;
  min-height: 120px;

  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  &:hover,
  &:focus {
    box-shadow:
      0px 0px 0px 5px rgba(223, 225, 228, 0.25),
      0px 6px 30px -2px rgba(0, 0, 0, 0.12);
  }
`;

const InlineDiv = styled.div`
  display: flex;
  color: #000000;
`;

const TitleDiv = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

const SubtitleDiv = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #737982;
  width: 100%;
  flex-grow: 0;
`;

/**
 * Creates a Target Fields page for Field Mapping guide
 */
const CreateFieldMappingOverview = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Create a Field Mapping";
  const description =
    "Merge gives you flexibility in how origin fields and targets are constructed, depending on your use case and intended user experience.";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview" className="mr-0">
        <p className="mb-3">There are two ways a Field Mapping can be created:</p>
        <TileRow>
          <Link
            to={LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_ACROSS_INTEGRATION.linkTo}
            className="d-flex w-50"
          >
            <FieldMappingLinkTile>
              <InlineDiv>
                <div className="d-flex">
                  <Globe size={16} className="mt-1 mr-2" />
                </div>
                <TitleDiv>For All Linked Accounts Across an Integration</TitleDiv>
              </InlineDiv>
              <SubtitleDiv>
                Integration-wide mappings allow you to map standard app fields that are not Common
                Model fields to all Linked Accounts for that app.
              </SubtitleDiv>
            </FieldMappingLinkTile>
          </Link>
          <Link
            to={LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_FOR_LINKED_ACCOUNT.linkTo}
            className="d-flex w-50"
          >
            <FieldMappingLinkTile>
              <InlineDiv>
                <User size={16} className="mt-1 mr-2" />
                <TitleDiv>For a Specific Linked Account</TitleDiv>
              </InlineDiv>
              <SubtitleDiv>
                Linked Account-level mappings allow you to map a custom field that is specific to a
                Linked Account.
              </SubtitleDiv>
            </FieldMappingLinkTile>
          </Link>
        </TileRow>
      </DocumentationSection>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};

export default CreateFieldMappingOverview;
